import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ServerErrorsService {
  public isServerErrorPage = signal(false);

  public serverErrorPopup = signal(false);

  public setIsServerErrorPage(value: boolean): void {
    this.isServerErrorPage.set(value);
  }

  public setServerErrorPopupStatus(status: boolean) {
    this.serverErrorPopup.set(status);
  }
}
